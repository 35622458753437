import { makeStyles, Slider, withStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  container: {
    padding: '1rem 18.5rem 5rem',
    [theme.breakpoints.down('md')]: {
      padding: '1rem 4.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 1.5rem',
    },
    width: '100%',
    display: 'flex',
  },
  description: {
    paddingLeft: '1rem',
    paddingTop: '.5rem',
    textAlign: 'justify',
  },
  image: {
    margin: 'auto'
  },
  title: {
    paddingLeft: '3rem',
    paddingBottom: '.5rem',
  },
  form: {
    alignItems: 'flex-start',
  },
  input: {
    border: `1px solid ${theme.palette.border}`,
    padding: `${theme.spacing(.5)}px ${theme.spacing(1)}px`,
    background: 'white',
  },
  itemForm: {
    paddingLeft: `${theme.spacing(4.5)}px !important`,
    paddingRight: `${theme.spacing(1)}px !important`,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: `${theme.spacing(1)}px !important`,
    },
  },
  helpText: {
    paddingLeft: '.5rem !important',
  },
  labelForm: {
    paddingBottom: theme.spacing(1),
    color: 'black',
    fontSize: 22,
    paddingLeft: '1rem',
    width: '100%',
  },
  formContainer: {
    margin: 0,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto auto auto',
    gridAutoFlow: 'column',
    gridGap: '2rem',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gridAutoRows: 'auto',
      gridAutoFlow: 'row',
    },
  },
  gridContainer: {
    margin: 0,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto auto',
    gridAutoFlow: 'column',
    gridGap: '2rem',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gridAutoRows: 'auto',
      gridAutoFlow: 'row',
    },
  },
  divider: {
    margin: '0 0 2rem',
    color: theme.palette.secondary.dark,
    width: '100%',
    height: 2,
    [theme.breakpoints.down('md')]: {
      margin: '2rem 0',
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    width: '100%',
    textTransform: 'uppercase',
    padding: '.5rem',
    borderRadius: 10,
    marginLeft: 'auto',
    textAlign: 'center',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      borderColor: 'white',
    },
  },
  buttonResponsive: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      marginTop: theme.spacing(2),
    },
  },
  inputFile: {
    display: 'none'
  },
  inputFileText: {
    paddingLeft: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px 0`,
      paddingLeft: theme.spacing(1),
    },
  },
  linearProgress: {
    margin: 'auto',
    width: '100%',
  },
  linearProgressRoot: {
    height: 6,
    borderRadius: 5,
    backgroundColor: theme.palette.linearProgress
  },
  footer: {
    paddingBottom: theme.spacing(23),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(10),
    },
  },
  checkbox: {
    color: theme.palette.secondary.dark,
  },
  checkboxIcon: {
    transform: "scale(1.3)",
  },
  sizeIcon: {
    fontSize: 30
  },
  flexStart: {
    alignContent: 'flex-start',
  },
  buttonDisabled: {
    color: `${theme.palette.secondary.main} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderColor: 'white !important',
    opacity: 0.7
  },
}));

export const CustomSlider = withStyles(theme =>({
  root: {
    height: 8
  },
  thumb: {
    height: 20,
    width: 20,
  },
  active: {},
  track: {
    height: 8,
  },
  rail: {
    height: 8,
    color: theme.palette.linearProgress
  }
}))(Slider);
