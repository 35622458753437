import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar,
  Typography,
} from '@material-ui/core';
import PowerModeInput from 'power-mode-input';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { CustomSlider, useStyles } from './styles';
import { careersList } from './constants';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Recaptcha from '../../../components/Recaptcha';
import { useApolloClient } from '@apollo/client';
import { SEND_CAREERS } from '../../../apollo/mutations';
import { APP_LANGUAGE } from '../../../i18n';
import i18n from 'i18next';


export default function CareerForm({id}) {
  const classes = useStyles();
  const {t} = useTranslation();
  const client = useApolloClient();
  const SALARY_TOTAL = 8000;
  const SALARY_INIT = 750;
  const SALARY_END = 3250;
  const [loading, setLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [value, setValue] = useState([SALARY_INIT * 100 / SALARY_TOTAL, SALARY_END * 100 / SALARY_TOTAL]);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState('emailSuccess');

  const [state, setState] = useState({
    lead: false,
    front: false,
    back: false,
    qa: false,
    fullName: '',
    email: '',
    phone: '',
    salaryInit: (value[0] * SALARY_TOTAL) / 100,
    salaryEnd: (value[1] * SALARY_TOTAL) / 100,
  });
  const [error, setError] = useState({
    fullName: '',
    email: '',
    phone: '',
    salaryInit: '',
    salaryEnd: '',
  });
  const [inputFile, setInputFile] = useState({
    name: '',
  });
  const inputNameRef = useRef();

  const fullNameInput = typeof document === `undefined` ? '' : document.querySelector('#full-name-input');
  const emailInput = typeof document === `undefined` ? '' : document.querySelector('#personal-email-input');
  const phoneInput = typeof document === `undefined` ? '' : document.querySelector('#phone-input');
  const salaryInitInput = typeof document === `undefined` ? '' : document.querySelector('#salary-init-input');
  const salaryEndInput = typeof document === `undefined` ? '' : document.querySelector('#salary-end-input');

  useEffect(() => {
    if (fullNameInput && emailInput && phoneInput && salaryInitInput && salaryEndInput) {
      PowerModeInput.make(document.querySelector('#full-name-input'), {
        color: '#022055'
      });
      PowerModeInput.make(document.querySelector('#personal-email-input'), {
        color: '#022055'
      });
      PowerModeInput.make(document.querySelector('#phone-input'), {
        color: '#022055'
      });
      PowerModeInput.make(document.querySelector('#salary-init-input'), {
        color: '#022055'
      });
      PowerModeInput.make(document.querySelector('#salary-end-input'), {
        color: '#022055'
      });
    }
  }, [fullNameInput, emailInput, phoneInput, salaryInitInput, salaryEndInput]);

  useEffect(() => {
    if (i18n.language) {
      setError({
        ...error,
        checkbox: '',
        fullName: '',
        email: '',
        phone: '',
        salaryInit: '',
        salaryEnd: '',
        resume: ''
      });
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  const handleChangeSlider = (event, newValue) => {
    setValue(newValue);
    setState({
      ...state,
      salaryInit: Math.round((newValue[0] * SALARY_TOTAL) / 100),
      salaryEnd: Math.round((newValue[1] * SALARY_TOTAL) / 100)
    });
    setError({
      ...error,
      salaryInit: '',
      salaryEnd: '',
    });
  };

  const handleChangeCheck = (event) => {
    setState({...state, [event.target.name]: event.target.checked});
    setError({
      ...error,
      checkbox: '',
    });
  };

  const handleChange = (event) => {
    setError({
      ...error,
      [event.target.name]: '',
    });
    setState({...state, [event.target.name]: event.target.value});
  };

  const handleSubmitOK = async () => {
    client.mutate({
      mutation: SEND_CAREERS,
      variables: {
        input: {
          name: state['fullName'],
          email: state['email'],
          salaryInit: state['salaryInit'],
          salaryEnd: state['salaryEnd'],
          backendDeveloper: state['back'],
          frontendDeveloper: state['front'],
          leadDeveloper: state['lead'],
          qualityAssurance: state['qa'],
          phone: state['phone'],
          resume: inputFile,
        }
      },
      context: {
        headers: {
          'Accept-Language': localStorage.getItem(APP_LANGUAGE)
        }
      }
    }).then(({data}) => {
        const {success, errors} = data.careers;
        if (success) {
          setLoading(false);
          setSendSuccess(true);
          setMessageSnackBar('emailSuccess');
          setOpenSnackBar(true);
          setRecaptcha(false);
        } else {
          setLoading(false);
          setRecaptcha(false);

          const temp = {};
          errors.forEach(error => {
            temp[error.field] = error.messages.join(' ,');
          });

          setError({
            ...error,
            ...temp
          });
        }
      }
    ).catch(() => {
      setMessageSnackBar('emailError');
      setOpenSnackBar(true);
      setRecaptcha(false);
      setLoading(false);
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const temp = {...error};
    let errorFlag = false;
    ['fullName', 'email', 'phone'].forEach((item) => {
      if (!Boolean(state[item]) || Boolean(error[item])) {
        temp[item] = 'required';
        errorFlag = true;
      }
    });

    if (!state['lead'] && !state['back'] && !state['front'] && !state['qa']) {
      temp['checkbox'] = 'requiredCheckbox';
      errorFlag = true;
    }

    if (inputFile.name === '') {
      temp['inputFile'] = 'required';
      errorFlag = true;
    }

    if (errorFlag) {
      setError(temp);
      setLoading(false);
    } else {
      setRecaptcha(true);
    }
  };


  return (
    <div>
      <Grid id={id} container justify={'center'}>
        <Grid item container justify={'center'} className={clsx(classes.container, classes.gridContainer)}>
          {careersList(t).map(career => (
            <Grid key={`career-${career.id}`} item container xs={12} className={classes.flexStart}>
              <div
                data-sal={'slide-down'}
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <Typography color={'primary'} gutterBottom className={classes.title}>
                  {career.title}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state[career.id] || false}
                      onChange={handleChangeCheck}
                      name={career.id}
                      color="primary"
                      classes={{
                        root: classes.checkbox
                      }}
                      icon={<CheckBoxOutlineBlankOutlinedIcon className={classes.sizeIcon} />}
                      checkedIcon={<CheckBoxOutlinedIcon className={classes.sizeIcon} />}
                    />
                  }
                  label={<Typography gutterBottom className={classes.description}>
                    {career.description}
                  </Typography>}
                  className={classes.form}
                />
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} className={classes.container}>
          <Grid item xs={12} className={classes.itemForm}>
            <FormHelperText error className={classes.helpText}>{t(error['checkbox'])}</FormHelperText>
          </Grid>
        </Grid>
        <form noValidate autoComplete="off" className={classes.container}>
          <Grid item container xs={12} className={classes.formContainer}>
            <Grid item xs={12} className={classes.itemForm}>
              <InputLabel shrink htmlFor="full-name-input" className={classes.labelForm}>
                {`${t('fullName')} *`}
              </InputLabel>
              <Input
                id="full-name-input"
                name={'fullName'}
                ref={inputNameRef}
                value={state['fullName']}
                placeholder={t('fullName')}
                error={Boolean(error['fullName'])}
                onChange={handleChange}
                fullWidth
                disableUnderline={!Boolean(error['fullName'])}
                className={classes.input}
              />
              <FormHelperText error className={classes.helpText}>{t(error['fullName'])}</FormHelperText>
            </Grid>
            <Grid item xs={12} className={classes.itemForm}>
              <InputLabel shrink htmlFor="personal-email-input" className={classes.labelForm}>
                {`${t('email')} *`}
              </InputLabel>
              <Grid item xs={12}>
                <Input
                  id="personal-email-input"
                  name={'email'}
                  className={classes.input}
                  placeholder={t('email')}
                  value={state['email']}
                  error={Boolean(error['email'])}
                  onChange={handleChange}
                  onBlur={(e) => {
                    if (e.target.value && !(/\S+@\S+\.\S+/.test(e.target.value))) {
                      setError({
                        ...error,
                        [e.target.name]: 'validEmail',
                      });
                    }
                  }}
                  disableUnderline={!Boolean(error['email'])}
                  fullWidth
                />
                <FormHelperText error className={classes.helpText}>{t(error['email'])}</FormHelperText>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.itemForm}>
              <InputLabel shrink htmlFor="personal-email-input" className={classes.labelForm}>
                {`${t('cellphone')} *`}
              </InputLabel>
              <Grid item xs={12}>
                <Input
                  id="phone-input"
                  name={'phone'}
                  placeholder={t('cellphone')}
                  className={classes.input}
                  value={state['phone']}
                  error={Boolean(error['phone'])}
                  onChange={handleChange}
                  onBlur={(e) => {
                    if (e.target.value && (!(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g.test(e.target.value))
                      || e.target.value.length <= 8)) {
                      setError({
                        ...error,
                        [e.target.name]: 'validCellphone',
                      });
                    }
                  }}
                  disableUnderline={!Boolean(error['phone'])}
                  fullWidth
                />
                <FormHelperText error className={classes.helpText}>{t(error['phone'])}</FormHelperText>
              </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.itemForm}>
              <Grid item xs={12}>
                <InputLabel shrink htmlFor="salary-init-input" className={classes.labelForm}>
                  {`${t('salaryPerMonth')} (${t('dollar')}) *`}
                </InputLabel>
              </Grid>
              <Grid item container justify={'space-between'} xs={12}>
                <Grid item xs={5}>
                  <Input
                    id="salary-init-input"
                    name={'salaryInit'}
                    value={state['salaryInit'] || 0}
                    className={classes.input}
                    error={Boolean(error['salaryInit'])}
                    onChange={(e) => {
                      if (e.target.value < state.salaryEnd) {
                        handleChange(e);
                        handleChangeSlider(e, [(parseInt(e.target.value, 10) * 100) / SALARY_TOTAL, value[1]]);
                      }
                    }}
                    disableUnderline
                    fullWidth
                    startAdornment={
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error className={classes.helpText}>{t(error['salaryInit'])}</FormHelperText>
                </Grid>
                <Grid item xs={5}>
                  <Input
                    id="salary-end-input"
                    name={'salaryEnd'}
                    startAdornment={
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    }
                    disableUnderline
                    value={state['salaryEnd'] || 0}
                    error={Boolean(error['salaryEnd'])}
                    onChange={(e) => {
                      if (e.target.value <= SALARY_TOTAL) {
                        handleChange(e);
                        handleChangeSlider(e, [value[0], (e.target.value * 100) / SALARY_TOTAL]);
                      }
                    }}
                    onBlur={(e) => {
                      if ((e.target.value >= SALARY_TOTAL) || (e.target.value < state['salaryInit'])) {
                        setError({
                          ...error,
                          [e.target.name]: 'salaryInitInvalid',
                        });
                      }
                    }}
                    fullWidth
                    className={classes.input}
                  />
                  <FormHelperText error className={classes.helpText}>{t(error['salaryEnd'])}</FormHelperText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={clsx(classes.itemForm, classes.linearProgress)}>
              <CustomSlider value={value} onChange={handleChangeSlider}
                            aria-labelledby="continuous-slider" />
            </Grid>
            <Grid item container alignItems={'center'} xs={12} className={classes.itemForm}>
              <Grid item xs={12}>
                <InputLabel shrink htmlFor="salary-init-input" className={classes.labelForm}>
                  {`${t('fieldToAttachCV')} *`}
                </InputLabel>
              </Grid>
              <Grid item md={5} sm={12} xs={12}>
                <input
                  accept=".doc,.docx,.pdf"
                  className={classes.inputFile}
                  id="icon-button-file"
                  type="file"
                  onChange={
                    (event) => {
                      setInputFile(event.target.files[0] || {
                        name: ''
                      });
                      setError({
                        ...error,
                        resume: ''
                      });
                    }
                  }
                />
                <label htmlFor="icon-button-file">
                  <div data-sal={'zoom-in'}
                       data-sal-delay="300"
                       data-sal-duration="1000"
                       data-sal-easing="ease"
                  >
                    <Button variant={'contained'} className={classes.button} component="span">
                      {t('fieldToAttachCV')}
                    </Button>
                  </div>
                </label>
              </Grid>
              <Grid item md={7} sm={12} className={classes.inputFileText}>
                {inputFile.name || t('formFileMessage')}
              </Grid>
              <Grid item xs={12}>
                {(!Boolean(inputFile.name) || Boolean(error['resume'])) &&
                <FormHelperText error
                                className={classes.helpText}>{t(error['inputFile']) || error['resume']}</FormHelperText>}
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item container xs={12} alignItems={'center'} className={clsx(classes.footer, classes.container)}>
          <Grid item sm={10} xs={12}>
            <Typography align={'center'}>
              {t('formMessage')}
            </Typography>
          </Grid>
          <Recaptcha submit={recaptcha} callback={handleSubmitOK} />
          <Grid item sm={2} xs={12}>
            <div data-sal={'zoom-in'}
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease"
            >
              <Button
                variant={'contained'}
                className={clsx(classes.button, classes.buttonResponsive)}
                onClick={handleSubmit}
                endIcon={loading && <CircularProgress size={20} color={'secondary'} />}
                disabled={sendSuccess}
                classes={{disabled: classes.buttonDisabled}}
              >
                {t('sendForm')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={t(messageSnackBar)}
      />
    </div>
  );
}
