import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  industriesContainer: {
    width: '100%',
    padding: '0 20rem',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  industriesText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  }
}));
