export const careersList = (t) => [
  {
    id: 'lead',
    title: t('leadDeveloper'),
    description: t('leadDeveloperDescription'),
  },
  {
    id: 'back',
    title: t('backEndDeveloper'),
    description: t('backEndDeveloperDescription'),
  },
  {
    id: 'front',
    title: t('frontEndDeveloper'),
    description: t('frontEndDeveloperDescription'),
  },
  {
    id: 'qa',
    title: t('qaDeveloper'),
    description: t('qaDeveloperDescription'),
  }
];
