import React from 'react';
import Header from '../components/Header';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Layout/Seo';
import Footer from '../components/Footer';
import CareersBanner from '../sections/Careers/Banner';
import Careers from '../sections/Careers/Careers';
import CareerForm from '../sections/Careers/CareerForm';

export default function CareersPage({location}) {
  const { state = {} } = location;
  const { subItemId = ''} = state || {};

  return (
    <Layout subItemId={subItemId}>
      <Seo title="Careers" />
      <Header location={location} />
      <CareersBanner />
      <Careers id={'lorem-ipsum'}  />
      <CareerForm />
      <Footer />
    </Layout>
  );
}
