import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Img from 'gatsby-image/withIEPolyfill';
import { CustomTitle } from '../../../components/CustomText';
import { useStyles } from './styles';
import { careersList } from './constants';


export default function Careers({id}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const imageData = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "career.png"}) {
     childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `);

  return (
    <Grid id={id} container justify={'center'} className={classes.container}>
      <CustomTitle title={t('careers')} />
      <Grid item lg={6} md={8} sm={9} className={classes.textContainer}>
        <Typography
          variant={'body1'}
          align={'center'}
          className={classes.description}
          data-sal={'slide-down'}
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          {t('careersDescription')}
        </Typography>
      </Grid>
      <Grid item container justify={'center'} xs={12}>
        <Grid item sm={6} xs={12}>
          <Img fluid={imageData.file.childImageSharp.fluid} objectFit="contain" className={classes.image} />
        </Grid>
      </Grid>
      {careersList(t).map((career) => {
        return (
          <Grid
            key={`service-card-${career.id}`}
            item xs={12}
            container
          />
        );
      })}
    </Grid>
  );
}
