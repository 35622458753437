export const careersList = (t) => [
  {
    id: 1,
    title: t('careerItem1'),
  },
  {
    id: 2,
    title: t('careerItem2'),
  },
  {
    id: 3,
    title: t('careerItem3'),
  },
  {
    id: 4,
    title: '',
  }
];
